@import "/src/variables";

.footer {
    display: flex;
    padding-bottom: 8px;
    width: 100%;
    justify-content: end;

    * {
        color: $secondary-color;

        &:hover {
            color: $primary-color;
        }
    }
}
