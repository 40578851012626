@import "/src/variables";
@import "/src/mixins";

.sponsors {
    h2 {
        color: $secondary-color;
        margin: 0;
        padding-left: 2px;
    }

    h3 {
        margin: 4px 16px;
        color: $primary-color;
    }
}

.sponsorlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 16px 32px;
    padding: 0;

    li {
        width: 66%;
        list-style: none;
        justify-content: center;
    }

    .img {
        display: flex;
        padding: 8px;
        border: 3px solid currentColor;
        border-radius: 12px;
        background-color: $sponsor-background;

        img {
            width: 100%;
            height: 120px;
            object-fit: contain;
        }
    }

    a {
        text-decoration: none;
    }

    p {
        margin: 8px 0 0 0;
        text-align: center;
    }

    [data-type="organisation"] > * {
        color: $tu-color;
    }

    [data-type="primary"] > * {
        color: $primary-color;
    }

    [data-type="secondary"] > * {
        color: $secondary-color;
    }
}

@include breakpoint(sm) {
    .sponsorlist li {
        width: 50%;
    }
}

@include breakpoint(md) {
    .sponsorlist li {
        width: 33%;
    }
}

@include breakpoint(xl) {
    .sponsorlist li {
        width: 20%;
    }
}
